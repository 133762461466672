.desktop {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

    .desktop .div {
        background-color: #ffffff;
        height: 5160px;
        overflow: hidden;
        position: relative;
        width: 1440px;
    }

    .desktop .overlap {
        height: 944px;
        left: -19px;
        position: absolute;
        top: 510px;
        width: 1422px;
    }

    .desktop .logo {
        height: 55px;
        left: 1123px;
        object-fit: cover;
        position: absolute;
        top: 882px;
        width: 211px;
    }

    .desktop .text-wrapper {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 26px;
        font-weight: 700;
        left: 120px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 890px;
        white-space: nowrap;
    }

    .desktop .less-than {
        height: 59px;
        left: 66px;
        position: absolute;
        top: 126px;
        width: 54px;
    }

    .desktop .group {
        height: 310px;
        left: 56px;
        position: absolute;
        top: 0;
        width: 1366px;
    }

    .desktop .overlap-group {
        background-color: #d9d9d980;
        border-radius: 10px;
        height: 310px;
        position: relative;
        width: 1364px;
    }

    .desktop .text-wrapper-2 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 20px;
        font-weight: 400;
        left: 106px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 0;
        width: 214px;
    }

    .desktop .more-than {
        height: 59px;
        left: 1366px;
        position: absolute;
        top: 126px;
        width: 54px;
    }

    .desktop .group-2 {
        height: 469px;
        left: 62px;
        position: absolute;
        top: 391px;
        width: 1351px;
    }

    .desktop .overlap-wrapper {
        height: 466px;
        left: 0;
        position: absolute;
        top: 3px;
        width: 318px;
    }

    .desktop .overlap-2 {
        height: 466px;
        position: relative;
    }

    .desktop .overlap-group-wrapper {
        height: 325px;
        left: 11px;
        position: absolute;
        top: 0;
        width: 293px;
    }

    .desktop .overlap-3 {
        height: 325px;
        position: relative;
        width: 297px;
    }

    .desktop .div-wrapper {
        height: 325px;
        left: 0;
        position: absolute;
        top: 0;
        width: 297px;
    }

    .desktop .overlap-group-2 {
        height: 325px;
        position: relative;
        width: 293px;
    }

    .desktop .ecaibinet-bietet {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 100px;
        width: 293px;
    }

    .desktop .span {
        font-weight: 700;
    }

    .desktop .text-wrapper-3 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
    }

    .desktop .text-wrapper-4 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 20px;
        font-weight: 700;
        left: 60px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 53px;
        width: 171px;
    }

    .desktop .ellipse {
        background-color: #d9d9d980;
        border-radius: 30px;
        height: 60px;
        left: 116px;
        position: absolute;
        top: 0;
        width: 60px;
    }

    .desktop .opera-glasses {
        height: 54px;
        left: 126px;
        position: absolute;
        top: 3px;
        width: 40px;
    }

    .desktop .rectangle {
        background-color: #d9d9d933;
        border-radius: 10px;
        height: 437px;
        left: 0;
        position: absolute;
        top: 29px;
        width: 318px;
    }

    .desktop .group-3 {
        height: 466px;
        left: 344px;
        position: absolute;
        top: 3px;
        width: 318px;
    }

    .desktop .overlap-4 {
        height: 466px;
        position: relative;
        width: 322px;
    }

    .desktop .group-4 {
        height: 466px;
        left: 0;
        position: absolute;
        top: 0;
        width: 322px;
    }

    .desktop .overlap-group-3 {
        height: 466px;
        position: relative;
        width: 318px;
    }

    .desktop .rectangle-2 {
        background-color: #d9d9d933;
        border-radius: 10px;
        height: 430px;
        left: 0;
        position: absolute;
        top: 36px;
        width: 318px;
    }

    .desktop .mit-dem-ecaibinet {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 9px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 101px;
        width: 298px;
    }

    .desktop .text-wrapper-5 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 20px;
        font-weight: 700;
        left: 33px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 61px;
        width: 263px;
    }

    .desktop .group-5 {
        background-color: #d9d9d980;
        border-radius: 30px/29px;
        height: 58px;
        left: 134px;
        position: absolute;
        top: 0;
        width: 60px;
    }

    .desktop .private-wall-mount {
        height: 49px;
        left: 143px;
        position: absolute;
        top: 4px;
        width: 40px;
    }

    .desktop .group-6 {
        height: 469px;
        left: 689px;
        position: absolute;
        top: 0;
        width: 318px;
    }

    .desktop .overlap-5 {
        height: 469px;
        position: relative;
        width: 322px;
    }

    .desktop .group-7 {
        height: 466px;
        left: 0;
        position: absolute;
        top: 3px;
        width: 322px;
    }

    .desktop .der-einkaufsprozess {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 9px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 109px;
        width: 298px;
    }

    .desktop .text-wrapper-6 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 20px;
        font-weight: 700;
        left: 55px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 58px;
        width: 218px;
    }

    .desktop .group-8 {
        background-color: #d9d9d980;
        border-radius: 30px/26.28px;
        height: 53px;
        left: 134px;
        position: absolute;
        top: 0;
        width: 60px;
    }

    .desktop .money-box {
        height: 56px;
        left: 139px;
        position: absolute;
        top: 0;
        width: 48px;
    }

    .desktop .group-9 {
        height: 466px;
        left: 1033px;
        position: absolute;
        top: 3px;
        width: 320px;
    }

    .desktop .text-wrapper-7 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 20px;
        font-weight: 700;
        left: 28px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 53px;
        width: 262px;
    }

    .desktop .group-10 {
        height: 466px;
        left: 0;
        position: absolute;
        top: 0;
        width: 318px;
    }

    .desktop .overlap-6 {
        height: 466px;
        position: relative;
        width: 320px;
    }

    .desktop .group-11 {
        background-color: #d9d9d980;
        border-radius: 30px;
        height: 60px;
        left: 134px;
        position: absolute;
        top: 0;
        width: 60px;
    }

    .desktop .group-12 {
        height: 458px;
        left: 0;
        position: absolute;
        top: 8px;
        width: 320px;
    }

    .desktop .overlap-group-4 {
        height: 458px;
        position: relative;
        width: 318px;
    }

    .desktop .rectangle-3 {
        background-color: #d9d9d933;
        border-radius: 10px;
        height: 437px;
        left: 0;
        position: absolute;
        top: 21px;
        width: 318px;
    }

    .desktop .ecaibinet-ist-ein {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 9px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 92px;
        width: 298px;
    }

    .desktop .free-shipping {
        height: 42px;
        left: 143px;
        position: absolute;
        top: 0;
        width: 42px;
    }

    .desktop .frame {
        background-color: #ffffff;
        border: 1px solid;
        border-color: #0000001a;
        border-radius: 2px;
        height: 260px;
        left: 120px;
        overflow: hidden;
        position: absolute;
        top: 35px;
        width: 1246px;
    }

    .desktop .overlap-7 {
        height: 243px;
        left: 27px;
        position: relative;
        top: 9px;
        width: 1206px;
    }

    .desktop .group-13 {
        height: 243px;
        left: 0;
        position: absolute;
        top: 0;
        width: 174px;
    }

    .desktop .overlap-group-5 {
        height: 243px;
        position: relative;
        width: 162px;
    }

    .desktop .favorite {
        height: 16px;
        left: 132px;
        position: absolute;
        top: 16px;
        width: 22px;
    }

    .desktop .einzelner {
        background-color: #ffffff80;
        border-radius: 10px;
        height: 234px;
        left: 0;
        position: absolute;
        top: 0;
        width: 162px;
    }

    .desktop .rectangle-4 {
        background-color: #d9d9d980;
        border-radius: 10px;
        height: 14px;
        left: 10px;
        position: absolute;
        top: 14px;
        width: 78px;
    }

    .desktop .text-wrapper-8 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 8px;
        font-weight: 400;
        left: 14px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 16px;
        width: 92px;
    }

    .desktop .text-wrapper-9 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 400;
        left: 10px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 140px;
        width: 133px;
    }

    .desktop .text-wrapper-10 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 400;
        left: 33px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 175px;
        width: 65px;
    }

    .desktop .text-wrapper-11 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 400;
        left: 33px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 194px;
        width: 92px;
    }

    .desktop .text-wrapper-12 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 400;
        left: 33px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 212px;
        width: 92px;
    }

    .desktop .price-tag-euro {
        height: 20px;
        left: 5px;
        position: absolute;
        top: 172px;
        width: 23px;
    }

    .desktop .combo-chart {
        height: 19px;
        left: 6px;
        position: absolute;
        top: 191px;
        width: 20px;
    }

    .desktop .delivery {
        height: 26px;
        left: 9px;
        position: absolute;
        top: 206px;
        width: 19px;
    }

    .desktop .star-half-empty {
        height: 19px;
        left: 109px;
        position: absolute;
        top: 209px;
        width: 23px;
    }

    .desktop .text-wrapper-13 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 400;
        left: 135px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 212px;
        width: 22px;
    }

    .desktop .siemens {
        height: 102px;
        left: 28px;
        object-fit: cover;
        position: absolute;
        top: 35px;
        width: 107px;
    }

    .desktop .group-14 {
        height: 243px;
        left: 172px;
        position: absolute;
        top: 0;
        width: 174px;
    }

    .desktop .group-15 {
        height: 243px;
        left: 344px;
        position: absolute;
        top: 0;
        width: 174px;
    }

    .desktop .puls-dimension {
        height: 97px;
        left: 33px;
        object-fit: cover;
        position: absolute;
        top: 32px;
        width: 97px;
    }

    .desktop .group-16 {
        height: 243px;
        left: 516px;
        position: absolute;
        top: 0;
        width: 174px;
    }

    .desktop .group-17 {
        height: 243px;
        left: 688px;
        position: absolute;
        top: 0;
        width: 174px;
    }

    .desktop .group-18 {
        height: 243px;
        left: 860px;
        position: absolute;
        top: 0;
        width: 174px;
    }

    .desktop .group-19 {
        height: 243px;
        left: 1032px;
        position: absolute;
        top: 0;
        width: 174px;
    }

    .desktop .image {
        height: 99px;
        left: 205px;
        object-fit: cover;
        position: absolute;
        top: 35px;
        width: 97px;
    }

    .desktop .frame-2 {
        height: 777px;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 167px;
        width: 1366px;
    }

    .desktop .text-wrapper-14 {
        -webkit-text-stroke: 1px #000000;
        color: #ea2a2a;
        font-family: "Inter", Helvetica;
        font-size: 96px;
        font-weight: 400;
        left: 29px;
        letter-spacing: 0;
        line-height: normal;
        mix-blend-mode: darken;
        position: absolute;
        text-shadow: 0px 4px 4px #00000040;
        top: 325px;
        transform: rotate(-30deg);
        width: 1367px;
    }

    .desktop .group-20 {
        height: 55px;
        left: 65px;
        position: absolute;
        top: 336px;
        width: 1356px;
    }

    .desktop .img {
        height: 55px;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 211px;
    }

    .desktop .das-weltweit-einzige {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 26px;
        font-weight: 800;
        left: 225px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 12px;
        white-space: nowrap;
        width: 1129px;
    }

    .desktop .logo-2 {
        height: 39px;
        left: 125px;
        object-fit: cover;
        position: absolute;
        top: 18px;
        width: 160px;
    }

    .desktop .menu {
        height: 27px;
        left: 300px;
        position: absolute;
        top: 22px;
        width: 27px;
    }

    .desktop .text-wrapper-15 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 15px;
        font-weight: 800;
        left: 341px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 26px;
        width: 97px;
    }

    .desktop .navbar {
        height: 26px;
        left: 449px;
        position: absolute;
        top: 26px;
        width: 395px;
    }

    .desktop .text-wrapper-16 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 15px;
        font-weight: 800;
        left: 82px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 0;
        width: 125px;
    }

    .desktop .text-wrapper-17 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 15px;
        font-weight: 800;
        left: 270px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 0;
        width: 125px;
    }

    .desktop .text-wrapper-18 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 15px;
        font-weight: 800;
        left: 184px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 0;
        width: 125px;
    }

    .desktop .text-wrapper-19 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 15px;
        font-weight: 800;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 0;
        width: 125px;
    }

    .desktop .group-21 {
        height: 59px;
        left: 94px;
        position: absolute;
        top: 429px;
        width: 1157px;
    }

    .desktop .overlap-8 {
        height: 59px;
        position: relative;
        width: 1159px;
    }

    .desktop .group-22 {
        height: 52px;
        left: 0;
        position: absolute;
        top: 6px;
        width: 82px;
    }

    .desktop .overlap-group-6 {
        height: 52px;
        position: relative;
        width: 80px;
    }

    .desktop .text-wrapper-20 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 37px;
        width: 80px;
    }

    .desktop .electric-fence {
        height: 44px;
        left: 22px;
        position: absolute;
        top: 0;
        width: 36px;
    }

    .desktop .group-23 {
        height: 49px;
        left: 81px;
        position: absolute;
        top: 9px;
        width: 82px;
    }

    .desktop .overlap-9 {
        height: 49px;
        position: relative;
        width: 80px;
    }

    .desktop .text-wrapper-21 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 34px;
        width: 80px;
    }

    .desktop .warning-shield {
        height: 39px;
        left: 23px;
        position: absolute;
        top: 0;
        width: 33px;
    }

    .desktop .group-24 {
        height: 44px;
        left: 162px;
        position: absolute;
        top: 14px;
        width: 82px;
    }

    .desktop .switch {
        height: 29px;
        left: 23px;
        position: absolute;
        top: 0;
        width: 35px;
    }

    .desktop .text-wrapper-22 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 29px;
        width: 80px;
    }

    .desktop .group-25 {
        height: 56px;
        left: 242px;
        position: absolute;
        top: 2px;
        width: 82px;
    }

    .desktop .overlap-10 {
        height: 56px;
        position: relative;
        width: 80px;
    }

    .desktop .text-wrapper-23 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 41px;
        width: 80px;
    }

    .desktop .safe-in {
        height: 52px;
        left: 20px;
        position: absolute;
        top: 0;
        width: 40px;
    }

    .desktop .group-26 {
        height: 48px;
        left: 323px;
        position: absolute;
        top: 10px;
        width: 82px;
    }

    .desktop .overlap-11 {
        height: 48px;
        position: relative;
        width: 80px;
    }

    .desktop .text-wrapper-24 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 33px;
        width: 80px;
    }

    .desktop .engine {
        height: 37px;
        left: 22px;
        position: absolute;
        top: 0;
        width: 37px;
    }

    .desktop .group-27 {
        height: 49px;
        left: 404px;
        position: absolute;
        top: 9px;
        width: 82px;
    }

    .desktop .plug {
        height: 36px;
        left: 19px;
        position: absolute;
        top: 0;
        width: 43px;
    }

    .desktop .group-28 {
        height: 45px;
        left: 471px;
        position: absolute;
        top: 13px;
        width: 82px;
    }

    .desktop .text-wrapper-25 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 30px;
        width: 80px;
    }

    .desktop .connected {
        height: 29px;
        left: 25px;
        position: absolute;
        top: 0;
        width: 31px;
    }

    .desktop .group-29 {
        height: 44px;
        left: 542px;
        position: absolute;
        top: 14px;
        width: 95px;
    }

    .desktop .overlap-12 {
        height: 44px;
        position: relative;
        width: 93px;
    }

    .desktop .mess {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 28px;
        width: 93px;
    }

    .desktop .ruler {
        height: 30px;
        left: 29px;
        position: absolute;
        top: 0;
        width: 35px;
    }

    .desktop .group-30 {
        height: 46px;
        left: 634px;
        position: absolute;
        top: 12px;
        width: 82px;
    }

    .desktop .overlap-13 {
        height: 46px;
        position: relative;
        width: 80px;
    }

    .desktop .text-wrapper-26 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 31px;
        width: 80px;
    }

    .desktop .internet-hub {
        height: 38px;
        left: 21px;
        position: absolute;
        top: 0;
        width: 38px;
    }

    .desktop .group-31 {
        height: 53px;
        left: 714px;
        position: absolute;
        top: 5px;
        width: 82px;
    }

    .desktop .text-wrapper-27 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 38px;
        width: 80px;
    }

    .desktop .proximity-sensor {
        height: 37px;
        left: 21px;
        position: absolute;
        top: 0;
        width: 37px;
    }

    .desktop .group-32 {
        height: 49px;
        left: 780px;
        position: absolute;
        top: 8px;
        width: 95px;
    }

    .desktop .text-wrapper-28 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 35px;
        width: 93px;
    }

    .desktop .pressure {
        height: 35px;
        left: 25px;
        position: absolute;
        top: 0;
        width: 44px;
    }

    .desktop .group-33 {
        height: 50px;
        left: 867px;
        position: absolute;
        top: 9px;
        width: 82px;
    }

    .desktop .text-wrapper-29 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 35px;
        width: 80px;
    }

    .desktop .stack-light {
        height: 35px;
        left: 22px;
        position: absolute;
        top: 0;
        width: 35px;
    }

    .desktop .group-34 {
        height: 58px;
        left: 939px;
        position: absolute;
        top: 0;
        width: 82px;
    }

    .desktop .overlap-14 {
        height: 58px;
        position: relative;
        width: 80px;
    }

    .desktop .text-wrapper-30 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 10px;
        font-weight: 600;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        text-align: center;
        top: 43px;
        width: 80px;
    }

    .desktop .cabinet-light {
        height: 50px;
        left: 19px;
        position: absolute;
        top: 0;
        width: 41px;
    }

    .desktop .group-35 {
        height: 50px;
        left: 1010px;
        position: absolute;
        top: 9px;
        width: 82px;
    }

    .desktop .light-on {
        height: 34px;
        left: 21px;
        position: absolute;
        top: 0;
        width: 37px;
    }

    .desktop .group-36 {
        height: 50px;
        left: 1077px;
        position: absolute;
        top: 9px;
        width: 82px;
    }

    .desktop .overlap-15 {
        height: 50px;
        position: relative;
        width: 80px;
    }

    .desktop .add-basket {
        height: 37px;
        left: 21px;
        position: absolute;
        top: 0;
        width: 38px;
    }

    .desktop .group-37 {
        height: 363px;
        left: 75px;
        position: absolute;
        top: 66px;
        width: 1218px;
    }

    .desktop .overlap-16 {
        background-color: #0c3d52;
        border-radius: 15px;
        height: 363px;
        position: relative;
        width: 1214px;
    }

    .desktop .KI-sucht-und-findet {
        color: #ffffff;
        font-family: "Inter", Helvetica;
        font-size: 40px;
        font-weight: 800;
        left: 65px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 27px;
    }

    .desktop .p {
        color: #ffffff;
        font-family: "Inter", Helvetica;
        font-size: 15px;
        font-weight: 800;
        left: 66px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 181px;
        width: 531px;
    }

    .desktop .electric {
        height: 363px;
        left: 669px;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 545px;
    }

    .desktop .group-38 {
        height: 45px;
        left: 80px;
        position: absolute;
        top: 270px;
        width: 407px;
    }

    .desktop .overlap-group-7 {
        background-color: #ffffff;
        border-radius: 25px;
        height: 45px;
        position: relative;
        width: 405px;
    }

    .desktop .text-wrapper-31 {
        color: #00000040;
        font-family: "Inter", Helvetica;
        font-size: 15px;
        font-weight: 600;
        left: 63px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 14px;
        white-space: nowrap;
    }

    .desktop .search {
        height: 34px;
        left: 9px;
        position: absolute;
        top: 5px;
        width: 34px;
    }

    .desktop .forward-button {
        height: 42px;
        left: 354px;
        position: absolute;
        top: 2px;
        width: 51px;
    }

    .desktop .frame-3 {
        background-color: #ffffff;
        border: 1px solid;
        border-color: #0000001a;
        border-radius: 2px;
        height: 490px;
        left: 36px;
        overflow: hidden;
        position: absolute;
        top: 1652px;
        width: 1367px;
    }

    .desktop .overlap-17 {
        background-image: url(../img/image-2.png);
        background-size: 100% 100%;
        height: 422px;
        left: 75px;
        position: absolute;
        top: 45px;
        width: 364px;
    }

    .desktop .vector {
        height: 365px;
        left: 27px;
        position: absolute;
        top: 21px;
        width: 278px;
    }

    .desktop .vector-2 {
        height: 365px;
        left: 66px;
        position: absolute;
        top: 44px;
        width: 278px;
    }

    .desktop .vector-3 {
        height: 22px;
        left: 111px;
        position: absolute;
        top: 178px;
        width: 46px;
    }

    .desktop .group-39 {
        height: 266px;
        left: 74px;
        position: absolute;
        top: 78px;
        width: 184px;
    }

    .desktop .group-40 {
        height: 275px;
        left: 600px;
        position: absolute;
        top: 110px;
        width: 665px;
    }

    .desktop .overlap-group-8 {
        height: 275px;
        position: relative;
        width: 659px;
    }

    .desktop .logo-3 {
        height: 55px;
        left: 258px;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 211px;
    }

    .desktop .werden-sie-teil-des {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 26px;
        font-weight: 700;
        left: 3px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 11px;
        width: 638px;
    }

    .desktop .text-wrapper-32 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 3px;
        letter-spacing: 0;
        line-height: 36px;
        position: absolute;
        top: 55px;
        width: 656px;
    }

    .desktop .rectangle-5 {
        background-color: #0c3d52;
        border-radius: 5px;
        height: 66px;
        left: 0;
        position: absolute;
        top: 209px;
        width: 258px;
    }

    .desktop .text-wrapper-33 {
        color: #ffffff;
        font-family: "Inter", Helvetica;
        font-size: 24px;
        font-weight: 400;
        left: 25px;
        letter-spacing: 0;
        line-height: 48px;
        position: absolute;
        top: 218px;
        white-space: nowrap;
    }

    .desktop .ob-maschinenbauer {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 26px;
        font-weight: 700;
        left: 37px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 2178px;
        white-space: nowrap;
        width: 1360px;
    }

    .desktop .overlap-18 {
        height: 260px;
        left: 270px;
        position: absolute;
        top: 2284px;
        width: 709px;
    }

    .desktop .group-41 {
        height: 55px;
        left: 109px;
        position: absolute;
        top: 0;
        width: 520px;
    }

    .desktop .logo-4 {
        height: 55px;
        left: 307px;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 211px;
    }

    .desktop .text-wrapper-34 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 26px;
        font-weight: 700;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 12px;
        white-space: nowrap;
    }

    .desktop .image-2 {
        height: 211px;
        left: 0;
        position: absolute;
        top: 49px;
        width: 709px;
    }

    .desktop .more-than-2 {
        height: 59px;
        left: 997px;
        position: absolute;
        top: 2375px;
        width: 54px;
    }

    .desktop .less-than-2 {
        height: 59px;
        left: 208px;
        position: absolute;
        top: 2375px;
        width: 54px;
    }

    .desktop .group-42 {
        height: 1750px;
        left: 126px;
        position: absolute;
        top: 2621px;
        width: 1027px;
    }

    .desktop .overlap-19 {
        height: 1750px;
        position: relative;
    }

    .desktop .group-43 {
        height: 121px;
        left: 36px;
        position: absolute;
        top: 1081px;
        width: 109px;
    }

    .desktop .overlap-20 {
        height: 121px;
        position: relative;
        width: 111px;
    }

    .desktop .people {
        height: 99px;
        left: 5px;
        position: absolute;
        top: 0;
        width: 102px;
    }

    .desktop .checked-checkbox {
        height: 29px;
        left: 36px;
        position: absolute;
        top: 92px;
        width: 39px;
    }

    .desktop .group-44 {
        height: 35px;
        left: 0;
        position: absolute;
        top: 72px;
        width: 22px;
    }

    .desktop .overlap-group-9 {
        height: 35px;
        position: relative;
        width: 20px;
    }

    .desktop .rectangle-6 {
        background-color: #000000;
        border-radius: 2px;
        height: 20px;
        left: 0;
        position: absolute;
        top: 3px;
        width: 20px;
    }

    .desktop .x {
        color: #ffffff;
        font-family: "Inter", Helvetica;
        font-size: 20px;
        font-weight: 700;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 0;
        width: 20px;
    }

    .desktop .group-45 {
        height: 35px;
        left: 89px;
        position: absolute;
        top: 72px;
        width: 22px;
    }

    .desktop .div-2 {
        height: 1750px;
        left: 0;
        position: absolute;
        top: 0;
        width: 1027px;
    }

    .desktop .time-limit {
        height: 112px;
        left: 869px;
        position: absolute;
        top: 763px;
        width: 129px;
    }

    .desktop .overlap-21 {
        height: 1750px;
        position: relative;
        width: 1055px;
    }

    .desktop .mobile-order {
        height: 127px;
        left: 869px;
        position: absolute;
        top: 1345px;
        width: 129px;
    }

    .desktop .group-46 {
        height: 1750px;
        left: 0;
        position: absolute;
        top: 0;
        width: 1055px;
    }

    .desktop .overlap-22 {
        height: 1750px;
        position: relative;
        width: 1027px;
    }

    .desktop .text-wrapper-35 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 30px;
        font-weight: 700;
        left: 571px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 23px;
        width: 352px;
    }

    .desktop .overlap-23 {
        height: 1678px;
        left: 0;
        position: absolute;
        top: 72px;
        width: 1027px;
    }

    .desktop .text-text-text {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 608px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 0;
    }

    .desktop .text-text-text-2 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 290px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 232px;
    }

    .desktop .text-text-text-3 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 589px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 465px;
    }

    .desktop .text-text-text-4 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 271px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 758px;
    }

    .desktop .text-text-text-5 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 602px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 1075px;
    }

    .desktop .text-wrapper-36 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 30px;
        font-weight: 700;
        left: 246px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 174px;
        width: 352px;
    }

    .desktop .text-wrapper-37 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 30px;
        font-weight: 700;
        left: 557px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 422px;
        width: 352px;
    }

    .desktop .text-wrapper-38 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 30px;
        font-weight: 700;
        left: 219px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 710px;
        width: 352px;
    }

    .desktop .vergleich-auswahl {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 30px;
        font-weight: 700;
        left: 581px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 1016px;
        width: 352px;
    }

    .desktop .text-wrapper-39 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 30px;
        font-weight: 700;
        left: 18px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 1300px;
        width: 352px;
    }

    .desktop .text-wrapper-40 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 30px;
        font-weight: 700;
        left: 589px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 1534px;
        width: 352px;
    }

    .desktop .ellipse-2 {
        background-color: #0c3d524c;
        border: 4px solid;
        border-color: #0c3d52;
        border-radius: 90px;
        height: 180px;
        left: 847px;
        position: absolute;
        top: 665px;
        width: 180px;
    }

    .desktop .ellipse-3 {
        background-color: #0c3d524c;
        border: 4px solid;
        border-color: #0c3d52;
        border-radius: 90px;
        height: 180px;
        left: 844px;
        position: absolute;
        top: 1247px;
        width: 180px;
    }

    .desktop .group-47 {
        height: 180px;
        left: 2px;
        position: absolute;
        top: 1498px;
        width: 180px;
    }

    .desktop .overlap-group-10 {
        border-radius: 90px;
        height: 180px;
        position: relative;
    }

    .desktop .handshake {
        height: 107px;
        left: 39px;
        position: absolute;
        top: 36px;
        width: 107px;
    }

    .desktop .div-3 {
        background-color: #0c3d524c;
        border: 4px solid;
        border-color: #0c3d52;
        border-radius: 90px;
        height: 180px;
        left: 0;
        position: absolute;
        top: 0;
        width: 180px;
    }

    .desktop .ellipse-4 {
        background-color: #0c3d524c;
        border: 4px solid;
        border-color: #0c3d52;
        border-radius: 90px;
        height: 180px;
        left: 0;
        position: absolute;
        top: 980px;
        width: 180px;
    }

    .desktop .import-pdf-wrapper {
        background-color: #0c3d524c;
        border: 4px solid;
        border-color: #0c3d52;
        border-radius: 90px;
        height: 180px;
        left: 844px;
        position: absolute;
        top: 155px;
        width: 180px;
    }

    .desktop .import-pdf {
        height: 128px;
        left: 30px;
        position: absolute;
        top: 18px;
        width: 118px;
    }

    .desktop .group-wrapper {
        background-color: #0c3d524c;
        border: 4px solid;
        border-color: #0c3d52;
        border-radius: 90px;
        height: 180px;
        left: 0;
        position: absolute;
        top: 408px;
        width: 180px;
    }

    .desktop .group-48 {
        background-image: url(../img/laptop-settings.png);
        background-size: 100% 100%;
        height: 113px;
        left: 24px;
        position: relative;
        top: 29px;
        width: 117px;
    }

    .desktop .line {
        height: 231px;
        left: 180px;
        object-fit: cover;
        position: absolute;
        top: 14px;
        width: 664px;
    }

    .desktop .line-2 {
        height: 254px;
        left: 180px;
        object-fit: cover;
        position: absolute;
        top: 244px;
        width: 667px;
    }

    .desktop .line-3 {
        height: 258px;
        left: 180px;
        object-fit: cover;
        position: absolute;
        top: 497px;
        width: 668px;
    }

    .desktop .line-4 {
        height: 316px;
        left: 180px;
        object-fit: cover;
        position: absolute;
        top: 754px;
        width: 668px;
    }

    .desktop .line-5 {
        height: 268px;
        left: 180px;
        object-fit: cover;
        position: absolute;
        top: 1069px;
        width: 664px;
    }

    .desktop .line-6 {
        height: 252px;
        left: 180px;
        object-fit: cover;
        position: absolute;
        top: 1336px;
        width: 664px;
    }

    .desktop .mind-map {
        height: 35px;
        left: 52px;
        position: absolute;
        top: 478px;
        width: 51px;
    }

    .desktop .text-text-text-6 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 18px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 1418px;
    }

    .desktop .text-text-text-7 {
        color: #000000;
        font-family: "Inter", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 608px;
        letter-spacing: 0;
        line-height: normal;
        position: absolute;
        top: 1665px;
    }

    .desktop .signing-a-document {
        height: 128px;
        left: 27px;
        position: absolute;
        top: 19px;
        width: 118px;
    }
